module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oberhuber Jenal Rechtsanwälte","short_name":"OJR","start_url":"/","background_color":"#ffffff","theme_color":"#1e805f","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../plugins/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["de","en"],"defaultLanguage":"en","redirect":true,"routes":{"/home":{"de":"/de","en":"/en"},"/404":{"de":"/de/404","en":"/en/404"},"/firm":{"de":"/de/kanzlei","en":"/en/firm"},"/lawyers":{"de":"/de/anwaelte","en":"/en/lawyers"},"/services":{"de":"/de/taetigkeitsbereiche","en":"/en/services"},"/contact":{"de":"/de/kontakt","en":"/en/contact"},"/imprint":{"de":"/de/impressum","en":"/en/imprint"},"/privacy-policy":{"de":"/de/datenschutz","en":"/en/privacy-policy"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://oberhuberjenal.matomo.cloud","siteUrl":"https://www.oberhuberjenal.li","disableCookies":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
