const React = require('react')
const { IntlProvider } = require('react-intl')
const { IntlPluginContextProvider } = require('./intlPluginContext')

module.exports = function wrapPageElement({ element, props }, pluginOptions) {
  const { pageContext } = props

  // TODO: What if no intl context is found? e.g. for page /404.html
  if (!pageContext.intl) {
    return (
      <IntlProvider
        locale={pluginOptions.defaultLanguage}
        defaultLocale={pluginOptions.defaultLanguage}
        messages={{}}
      >
        <IntlPluginContextProvider
          value={{ languages: pluginOptions.languages }}
        >
          {element}
        </IntlPluginContextProvider>
      </IntlProvider>
    )
  }

  return (
    <IntlProvider
      locale={pageContext.intl.language}
      defaultLocale={pageContext.intl.defaultLanguage}
      messages={pageContext.intl.messages}
    >
      <IntlPluginContextProvider value={pageContext.intl}>
        {element}
      </IntlPluginContextProvider>
    </IntlProvider>
  )
}
